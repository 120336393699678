import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Links from '../components/Links'
import '../assets/scss/init.scss'

class IndexRoute extends React.Component {
  render() {
    const { title, subtitle, author } = this.props.data.site.siteMetadata

    return (
      <div className="home">
        <Helmet>
          <title>{title} - product development software engineer</title>
          <meta name="description" content={subtitle} />
        </Helmet>
        <div className="left">
          <div className="top" />
          <div className="middle" />
          <div className="bottom" />
        </div>
        <div className="right">
          <div className="top" />
          <div className="middle">
            <h1>Dusan Gledovic</h1>
            <p>
              I am a full-stack product development software engineer, leader, creative problem
              solver, and pragmatist.
            </p>
            <p>
              I live with my wonderful family in{' '}
              <span role="img" aria-label="sunny">
                🌞
              </span>{' '}
              Brisbane, Australia - the best place on Earth.
            </p>
          </div>
          <div className="bottom">
            <Links data={author} />
          </div>
        </div>
      </div>
    );
  }
}

export default IndexRoute

export const pageQuery = graphql`
  query NewHomeQuery {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        author {
          name
          linkedin
          github
        }
      }
    }
  }
`
