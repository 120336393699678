import React from 'react';
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import '../assets/scss/init.scss';

class NotFoundRoute extends React.Component {
  render() {
    const { title, subtitle } = this.props.data.site.siteMetadata;

    return (
      <div className="home">
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={subtitle} />
        </Helmet>
        <div className="left">
          <div className="top" />
          <div className="middle" />
          <div className="bottom" />
        </div>
        <div className="right">
          <div className="top" />
          <div className="middle">
            <h1>Page Not Found</h1>
            <p className="go-home-link">
              <Link to="/">home</Link>
            </p>
          </div>
          <div className="bottom">
          </div>
        </div>
      </div>
    );
  }
}

export default NotFoundRoute;

export const pageQuery = graphql`
  query NotFoundQuery {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        author {
          name
          linkedin
          github
        }
      }
    }
  }
`;
